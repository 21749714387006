<template>
  <div class="main-content" style="
    padding-bottom: 10vh !important;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      background-position: center;
    ">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end">
        <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
      </div>
      <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
        <div class="px-2 py-1 rounded" style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #add8e6;
          ">
          <p class="mb-0">{{ $t("loading...") }}</p>
        </div>
      </div>
    </div>
    <div class="m-3 mx-3 mt-4">
      <div class="header">
          <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
          <h1 class="buy_text text">{{ $t("buy_etnex") }}</h1>
          <div></div>
          <!-- <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i> -->
          <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
      </div>
      <h2 class="text_h2_etnex text text-light-green">{{ $t("etnex_purchase") }}</h2>
      <div class="etnex-package-container black-dark-blue-card">
        <div class="package-content">
          <div class="purchase-details">
            <!-- <h3 class="purchase_text text">{{ $t("purchase_det") }}</h3> -->
            <!-- <div class="qr-code-container">
              <img src="../../../assets/images/etnex_buy/qr.png" alt="QR Code" class="qr-code">
            </div> -->
            <!-- <div class="contract-address-container" style="padding-top: 20px;">
              <span class="address_text text">{{ $t("con_address") }}</span>
              <span class="address">Tbxnnc...8sjd7Y
                <span class="copy-icon">
                  <img src="../../../assets/images/etnex_buy/copy.png" alt="copy" class="copy-icon">
                </span>
              </span>
            </div> -->
          </div>

          <h3 class="statement_text text text-light-green">{{ $t("purchase_statement") }}</h3>
          <div class="purchase-statement-container">
            <!-- <div class="hash-input">
              <label for="hash" class="hash_text text">{{ $t("hash") }}</label>
              <input type="text" class="hash_input" placeholder="Hash">
            </div> -->
            <div>
              <div class="custom-input-group mb-3">
                <label class="amounts_text">{{ $t("custom_amount") }}</label>
                <div>
                <input type="number" class="custom-input form-control text-white" :placeholder="$t('min_usd')" id="price" v-model="selectedPrice"/>
                </div>
              </div>
              <label class="amounts_text">{{ $t("package_amount") }}</label>
              <div class="package-amounts">
                <!-- <div v-if="group.display != 2"> -->
                <label v-for="group in amounts" :key="group.id" class="amount-option" :class="{ 'disabled-radio': group.display == 2 }">
                  <input type="radio" name="package" id="package" :value="group.id" v-model="selectedAmount" :disabled="group.display == 2">
                  <div class="amount-content">
                    <span class="amount-value">{{ group.display_price }}</span>
                    <span class="amount-multiplier">{{ $i18n.locale === 'en' ? group.package_name_en : group.package_name }}</span>
                  </div>
                </label>

                </div>
              <!-- </div> -->
            </div>
            
            
            <!-- <label class="upload_text text">{{ $t("upload_statement") }}</label>
            <div class="upload-statement">
              <label for="file-upload" class="upload-btn">
                <img src="../../../assets/images/etnex_buy/upload_icon.png" alt="upload" class="upload-icon">
                <span class="upload-text text">{{ fileName || $t("browse") }}</span>
              </label>
              <input id="file-upload" type="file" @change="handleFileUpload" style="display: none;" />
            </div> -->

            <button class="make-purchase-button" @click="checkfees()">
              {{ $t("make_purchase") }} <img src="../../../assets/images/etnex_buy/swap.png" alt="swap" class="swap-icon">
            </button>
            <enter-pin ref="enterPin" @submit-pin="submitForm"></enter-pin>
            <!-- <Note v-if="showNote" @agree-fees="handleFeesAgree" /> -->
          </div>
        </div>
      </div>
      <h2 class="text_h2_etnex text text-light-green mt-3 pt-10">{{ $t("recent_transactions") }}</h2>
        <div class="scrollable-container">
          <div v-if="packageRecord.length > 0">
            <div class="notification-card" v-for="record in packageRecord" :key="record.id">
                <div class="icon">
                    <img src="../../../assets/images/etnex_wallet/leftright.png" alt="icon" class="icon" width="50px">
                </div>
                <div class="content">
                <div class="upper-row">
                    <span class="title text">{{ $i18n.locale === 'en' ? record.package.package_name_en : record.package.package_name }}</span>
                    <span class="date text">{{ formatDate(record.created_at) }}</span>
                </div>
                <div class="lower-row">
                  <div>
                    <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon " style="max-width: 14px;"/>            
                    <span class="text mb-0 ml-0">{{ parseFloat(record.coin_record.total_amount) }} ETNEX</span>
                  </div>
                  <span class="change text-light-green font-weight-bold">{{ record.price }} USDT</span>   
                </div>
              </div>
            </div>
          </div>
          <h3 v-else class="no-data-text text">
            {{ $t("no_data") }}
          </h3>
          <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                    {{ $t("load_more") }}
                </button>
            </div>
        </div>
    </div>
    <Dialog ref="msg"></Dialog>
    <Success v-if="SuccessModal" @closePopup="SuccessModal = false" :message="successMessage" />
  </div>
</template>
<script>
import EnterPin from '../pin/enter_pin.vue';
import { upgradePackage, getPackage, getMemberInfo, getUsersPackage } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Dialog from "../../../components/dialog.vue";
import Success from '../popup/profilesuccess.vue';
import { mapGetters } from "vuex";
export default {
  components: {
    ...mapGetters(["lang"]),
    EnterPin,
    Dialog,
    Success,
    },
  data() {
    return {
      pay_type: 'point1',
      fileName: '',
      selectedAmount: '',
      SuccessModal: false,
      amounts: [],
      showNote: false,
      check_fees: '',
      isLoading: false,
      currentPage: 1,
      packageRecord: [],
      moreRecords: false,
      selectedPrice: '',
    };
  },
  watch: {

      selectedPrice(val){
        var tempt = this.amounts.find((el)=> parseFloat(el.price) == parseFloat(val) );
        console.log(tempt);
        if (tempt){
          this.selectedAmount = tempt['id'];
        }
        else {
          this.selectedAmount = 9;
        }

      },
      selectedAmount(val){
        var tmpPrice = this.amounts.find((el)=> el.id == val )['price'];
        if(tmpPrice != '0.00'){
          this.selectedPrice = tmpPrice;

        }

        
      },
  },
  mounted() {
    this.loadPackageAmount();
    this.loadItems();
    this.loadItems2();
  },
  methods: {
    
    checkfees() {
      if(this.selectedAmount == ""){
          this.$refs.msg.makeToast("danger", this.$t("need_select_package"));
      }else{
        this.handleFeesAgree();
      }
    },

    handleFeesAgree() {
        this.$refs.enterPin.open();
    },

    goBack() {
      this.$router.go(-1)
    },
    goHistory() {
      this.$router.push("/web/package/packageHistory");
    },
    open() {
      this.$refs.enterPin.open();
    },

    submitForm(pin) {
        let formData = new FormData();
        var self = this;
        formData.append("pay_type", this.pay_type);
        formData.append("user_group_id", this.selectedAmount);
        formData.append("sec_password", pin);
        formData.append("price", this.selectedPrice);
        var result = upgradePackage(formData);
        self.isLoading = true;

        result
          .then(function (value) {
              console.log(value.data);
              if (value.data.code == 0) {
                self.successMessage = "success";
                self.SuccessModal = true;
              } else {
                  self.$refs.msg.makeToast("danger", self.$t(value.data.message));
              }
              self.sending = false;
              self.isLoading = false;
              self.packageRecord = [];
              self.currentPage = 1;
              self.loadItems2();
          })
          .catch(function (error) {
              self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
              self.sending = false;
              self.isLoading = false;
          });
    },

    loadPackageAmount(){
      var result = getPackage();
      var self = this;
      result
        .then(function (value) {
          self.amounts = value.data.data.user_group;
          self.price = value.data.data.user_group.price;
          // console.log(value);
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },

    loadItems(){
      var result = getMemberInfo();
      var self = this;
      result
        .then(function (value) {
          self.check_fees = value.data.data.first_time;
          // console.log(self.check_fees);
        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },

    formatDate(datetime) {
      return datetime.split(' ')[0];
    },
    loadItems2(){
      var result = getUsersPackage(
        this.currentPage,
      );
      var self = this;
      result
        .then(function (value) {
          self.currentPage += 1;
          // console.log("API Response:", value);
          self.packageRecord = self.packageRecord.concat(value.data.data.user_package.data);
          if (!value.data.data.user_package.next_page_url) {
            self.moreRecords = false;
          }else{
            self.moreRecords = true;
          }
          // console.log(self.walletRecord);

        })
        .catch(function (error) {
          self.isLoading = false;
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
    },

    handleFileUpload(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        this.fileName = files[0].name;
      }
    }
  }
};
</script>

<style scoped>
/* text css */

.text{
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}

.buy_text{
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 7px;
}
.purchase_text{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px; 
}
.address_text{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.statement_text{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.hash_text{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.amounts_text{
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.upload_text{
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.upload-text{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.address{
  color: var(--Secondary, #7E8088);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text_h2_etnex{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

/* text css end */

.package-amounts{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.hash_input{
  border-radius: 7px !important;
  border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%)) !important;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contract-address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #393737;
}

.address {
  margin-right: 5px;
}

.copy-icon {
  cursor: pointer;
}

.etnex-package-container {
  max-width: 100%;
  margin: auto;
  color: white;
  padding: 1px 12px 10px 12px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  background: linear-gradient(90deg, #152331 0%, #000000 100%);
}

.header {
  display: flex;
  /* padding: 0px 16px; */
  margin-top: 4rem !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.back-arrow-icon, .info-icon {
  cursor: pointer;
}

.info-icon {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}

.package-content h2 {
  margin-bottom: 16px;
}

.purchase-details {
  border-radius: 8px;
  margin-bottom: 16px;
}

.qr-code {
  margin: 16px 0;
}


.make-purchase-button {
  width: 100%;
  border-radius: 8px;
  background: var(--Primary, #3BEDEA);
  color: rgb(0, 0, 0);
  padding: 12px;
  border: none;
  margin-top: 8px;
  color: var(--Dark-Purple, #251740);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}


.purchase-statement-container {
  padding: 10px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.hash-input label,
.hash-input input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.hash-input input {
  background: #333;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #ccc;
}




.upload-button {
  padding: 10px;
  background: #3BEDEA;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}



.package-amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.amount-option {
  flex: 1 1 calc(33.333% - 10px);
  border-radius: 8px;
  background: var(--Menu-Dark, #222223);
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}

.amount-option input[type="radio"] {
  display: none;
}

.amount-option span {
  display: block;
}



.amount-option input[type="radio"]:checked ~ .amount-content::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #3BEDEA;
  border-radius: 5px;
  pointer-events: none;
}

.upload-statement {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.upload-btn {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid var(--Neon-Blue, #0BDDCC);
  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
  border: 1px solid #3BEDEA;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: none;
}


.upload-icon {
  margin-right: 5px;
}

.amount-option.disabled-radio {
  /* color: #c0c0c0 !important; */
  background-color: #454444 !important;
}

.scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
    }
.notification-card {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #152331 0%, #000000 100%);
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 7px;
}

.icon {
    margin-right: 5px;
    flex-shrink: 0;
}

.icon img {
    background-color: transparent;
    border-radius: 5px;
    padding: 8px;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.upper-row, .lower-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}

.date {
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
}

.stat-circle-container {
    flex: 0 0 auto;
}
.stat-circle circle.progress
    {
        border: 1px solid yellow;
        fill: none;
        stroke-width: 2;
        /* stroke-dasharray: 51 51; */
        /* stroke-dashoffset: -51; */
    }
circle.bg
{
    fill: #C20651;
    stroke: #251740;
    stroke-width: 3;
}

text
{
    font-size: 4px;
    text-anchor: middle;
    fill: #fff;
}
.text_main_title{
  flex-grow: 1;
  text-align: center;
}


.no-data-text{
  font-weight: 400;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  margin-top: 40px;
  color: var(--Secondary, #7E8088) !important;
}
</style>
